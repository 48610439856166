<template>
    <div class="tinymce-editor">
        <editor id="tinymce"
                v-model="myValue"
                :init="init"
                :disabled="disabled"
                @onClick="onClick">
        </editor>
    </div>
</template>
<script>
    // 基本配置
    import tinymce from 'tinymce/tinymce'
    import Editor from '@tinymce/tinymce-vue'
    import 'tinymce/themes/silver'

    // 更多插件参考：https://www.tiny.cloud/docs/plugins/
    // import 'tinymce/plugins/media' 插入视频插件
    // import 'tinymce/plugins/image'// 插入上传图片插件
    // import 'tinymce/plugins/table'// 插入表格插件
    import 'tinymce/plugins/lists'// 列表插件
    import 'tinymce/plugins/wordcount'// 字数统计插件
    import 'tinymce/plugins/fullscreen'// 全屏插件
    import 'tinymce/plugins/print'// 打印插件
    import 'tinymce/plugins/help'// 帮助插件
    import 'tinymce/plugins/preview'// 预览插件
    import 'tinymce/plugins/searchreplace'// 搜索和替换插件
    import 'tinymce/plugins/link'// 链接插件
    import 'tinymce/plugins/imagetools'// 图片工具插件
    import 'tinymce/icons/default/icons.min.js'

    export default {
        components: {
            Editor
        },
        props: {
            value: {
                type: String,
                default: ''
            },
            disabled: {
                type: Boolean,
                default: false
            },
            plugins: {
                type: [String, Array],
                default: 'lists image table wordcount print fullscreen help preview searchreplace link imagetools lists'
            },
            toolbar: {
                type: [String, Array],
                default: 'undo redo |' +
                    'formatselect |' +
                    'bold italic forecolor backcolor |' +
                    'alignleft aligncenter alignright alignjustify outdent indent removeformat|' +
                    'bullist numlist |' +
                    'image table |' +
                    'print searchreplace'
            },
            contextmenu: {
                type: [String, Array],
                default: "link searchreplace"
            }
        },
        data() {
            return {
                init: {
                    language_url: '/tinymce/langs/zh_CN.js',
                    language: 'zh_CN',
                    skin_url: '/tinymce/skins/ui/oxide',
                    height: 400,
                    branding: false,
                    plugins: this.plugins,
                    toolbar: this.toolbar,
                    contextmenu: this.contextmenu,
                  // eslint-disable-next-line no-unused-vars
                    images_upload_handler: (blobInfo, success, failure) => {
                        const img = 'data:image/jpeg;base64,' + blobInfo.base64();
                        success(img)
                    }
                },
                myValue: this.value
            }
        },
        mounted() {
            tinymce.init({})
        },
        methods: {
            // 添加相关的事件，可用的事件参照文档=> https://github.com/tinymce/tinymce-vue => All available events
            // 需要什么事件可以自己增加
            onClick(e) {
                this.$emit('onClick', e, tinymce)
            },
            // 可以添加一些自己的自定义事件，如清空内容
            clear() {
                this.myValue = ''
            }
        },
        watch: {
            value(newValue) {
                this.myValue = newValue
            },
            myValue(newValue) {
                this.$emit('input', newValue)
            }
        }
    }

</script>
